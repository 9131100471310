import Cookies from 'js-cookie';

import { get, patch, post, put } from 'api/axiosInstance';
import { RequestsEnum, CookiesType } from 'types';
import { getTableParams } from 'utils/helpers';

export const getAllBackOfficeJobs = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.BackofficeJobs}${queryParams}`);
};

export const getBOJobById = async (jobId) => get(`${RequestsEnum.BackofficeJobs}/${jobId}`);

export const getApplicationMessages = async (clientId) =>
	get(`${RequestsEnum.ClientBOApplicationMessages}/${clientId}`);

export const getAllAtsJobs = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.AtsJobs}${queryParams}`);
};

export const getAllClientContextJobsJobs = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.BackofficeClientContextJobs}${queryParams}`);
};

export const getClientContextDashboardJobs = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.BOClientContextDashboardJobs}${queryParams}`);
};

export const getJobById = async (jobId) => get(`${RequestsEnum.Job}/${jobId}`);

export const getAtsJobActive = async () => get(RequestsEnum.AtsJobsActive);

export const getJobRestrictedUsers = async (jobId) =>
	get(`${RequestsEnum.Job}/${jobId}${RequestsEnum.Restrictions}`);

export const getOpenJobById = async (jobId) => get(`${RequestsEnum.JobOpen}/${jobId}`);

export const getOpenJobAssessment = async ({ jobId, isFreeEndpoint = false }) => {
	const endpoint = isFreeEndpoint ? RequestsEnum.AssessmentJob : RequestsEnum.AssessmentJobOpen;

	return get(`${endpoint}/${jobId}`);
};

export const applyOnJob = async (data) => post(RequestsEnum.CandidateOpen, data);

export const applyOnCompany = async (data) => post(RequestsEnum.CandidateUnassignedOpen, data);

export const sendAQuestion = async (data) => post(RequestsEnum.CandidateSendMessage, data);

export const getJobTypes = async () => get(RequestsEnum.JobType);

export const getJobUpgrades = async () => get(`${RequestsEnum.JobUpgrades}`);

export const getJobCandidatesCsvBO = async (jobId) =>
	get(`${RequestsEnum.BackofficeCandidatesCsv}/${jobId}`);

export const getJobCandidatesCsvAts = async (jobId) =>
	get(`${RequestsEnum.AtsCandidatesCsv}/${jobId}`);

export const getJobCandidatesAtsZipFile = async (params) => {
	const baseUrl = process.env.REACT_APP_API_URL;
	const url = `${baseUrl}${RequestsEnum.AtsCandidatesZip}${params}`;
	const token = Cookies.get(CookiesType.JWT);

	const options = {
		headers: {
			'X-Authorization': `Bearer ${token}`,
			Accept: 'application/zip',
		},
	};

	try {
		const file = await fetch(url, options).then((res) => res?.blob());

		return file;
	} catch (e) {
		console.error('error with zip archive downloading', e);
	}
};

export const saveFreeJob = async (data) => post(`${RequestsEnum.JobBackofficeSelfService}`, data);

export const savePaidJob = async (data) => post(`${RequestsEnum.JobBackofficePremium}`, data);

export const updateFreeJob = async (data) => put(`${RequestsEnum.JobBackofficeSelfService}`, data);

export const updatePaidJob = async (data) => put(`${RequestsEnum.JobBackofficePremium}`, data);

export const saveAtsFreeJob = async (data) => post(`${RequestsEnum.Job}`, data);

export const saveAtsPaidJob = async (data) => post(`${RequestsEnum.JobPremium}`, data);

export const updateAtsFreeJob = async (data) => put(`${RequestsEnum.ATSJobSelfService}`, data);

export const updateAtsPaidJob = async (data) => put(`${RequestsEnum.JobPremium}`, data);

export const saveJobRestrictions = async ({ jobId, restrictedUserIds }) =>
	post(`${RequestsEnum.Job}/${jobId}${RequestsEnum.Restrictions}`, { restrictedUserIds });

export const updateJobRestrictions = async ({ jobId, restrictedUserIds }) =>
	put(`${RequestsEnum.Job}/${jobId}${RequestsEnum.Restrictions}`, { restrictedUserIds });

export const boostPaidJob = async (data) => patch(`${RequestsEnum.JobPremiumBoost}`, data);

export const archiveAndUnarchiveJob = async (id, data) =>
	patch(`${RequestsEnum.Job}/${id}/state`, data);

export const updateRejectionMessagePerJob = async (id, data) =>
	patch(`${RequestsEnum.AtsJobs}/${id}${RequestsEnum.RejectionMessage}`, data);

export const getJobIndustries = async () => get(`${RequestsEnum.JobIndustry}`);

export const getAtsAllJobs = async () => get(RequestsEnum.ATSJobAll);

export const getAllGravityJobs = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.AtsJobGravity}${queryParams}`);
};

export const getGravityCandidatesForJob = async ({ jobId, page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.AtsJobGravity}/${jobId}${RequestsEnum.Application}${queryParams}`);
};

export const boostBOPaidJob = async (id) => patch(`${RequestsEnum.BOJobBoost}`, id);

export const getVeritoneUrl = async (jobId) =>
	get(`${RequestsEnum.BackofficeJobs}/${jobId}/veritone-url`, { 'X-Hide-Alert-Error': true });

const jobsService = {
	applyOnJob,
	applyOnCompany,
	sendAQuestion,
	archiveAndUnarchiveJob,
	getAllBackOfficeJobs,
	getAllAtsJobs,
	getAllClientContextJobsJobs,
	getBOJobById,
	getApplicationMessages,
	getJobById,
	getAtsJobActive,
	getJobTypes,
	getJobUpgrades,
	getOpenJobById,
	getOpenJobAssessment,
	getJobCandidatesCsvBO,
	getJobCandidatesCsvAts,
	getJobRestrictedUsers,
	saveJobRestrictions,
	getJobCandidatesAtsZipFile,
	saveAtsFreeJob,
	saveAtsPaidJob,
	updateAtsFreeJob,
	updateAtsPaidJob,
	saveFreeJob,
	savePaidJob,
	updateFreeJob,
	updatePaidJob,
	boostPaidJob,
	updateRejectionMessagePerJob,
	updateJobRestrictions,
	getJobIndustries,
	getClientContextDashboardJobs,
	getAtsAllJobs,
	getAllGravityJobs,
	getGravityCandidatesForJob,
	boostBOPaidJob,
	getVeritoneUrl,
};

export default jobsService;
